<template>
    <div class="PickupAudit">
        <el-dialog title="审核详情" :visible.sync="AuditDetailDialogTable">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="reviewListData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column width="120" label="审核人" prop="creator" />
                <el-table-column width="140" label="审核日期" prop="updateTime" />
                <el-table-column width="100" label="审核意见" prop="showAdvice" />
                <el-table-column min-width="180" label="备注" prop="remark" />
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="AuditDetailDialogTable = false" type="primary" size="small">关 闭</el-button>
                <template v-if="haveReview && checkShowRevertButton()">
                    <el-button @click="reviewAudit" size="small">反审核</el-button>
                </template>
                <template v-if="haveReject && checkShowRejectButton()">
                    <el-button @click="rejectAudit" size="small">驳回</el-button>
                </template>
            </div>
        </el-dialog>
        <ef-review-biz ref="review" :baseUrl="baseUrl" @completed="complete" :advice="nowAdviceBtn" required-remark />
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfReviewBiz from 'components/EfReviewBiz';
import StockBizBizCommon from 'js/StockBizCommon';

export default {
    name: 'PickupReturnAudit',
    components: { EfReviewBiz },
    props: {
        reviewPrivilegeFlag: {
            type: String,
            default: '',
        },
        haveReview: {
            type: Boolean,
            default: true,
        },
        haveReject: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            audit: {
                code: '',
                bizCode: '',
                auditDate: Util.nowFormatDate(),
                advice: '',
                remark: '',
            },
            nowAdviceBtn: -1,
            loginStaffCode: this.$store.state.session.code,
            lastReviewData: null,
            reviewListData: [],
            baseUrl: '',
            stockStatus: '',
            returnAuditDialogTable: false,
            AuditDetailDialogTable: false,
        };
    },
    methods: {
        checkShowRevertButton() {
            if (
                this.reviewPrivilegeFlag &&
                this.lastReviewData &&
                this.lastReviewData.advice == 1 &&
                this.hasPrivilege(this.reviewPrivilegeFlag) &&
                this.lastReviewData.creatorCode == this.loginStaffCode
            ) {
                if (this.stockStatus) {
                    return this.stockStatus == StockBizBizCommon.StockBizStatusEnum.NEED_CHANGE;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        checkShowRejectButton() {
            const temResult =
                this.lastReviewData &&
                this.lastReviewData.advice == 1 &&
                !this.checkShowRevertButton() &&
                this.hasPrivilege('biz.biz.reject');
            if (temResult) {
                if (this.stockStatus) {
                    return this.stockStatus == StockBizBizCommon.StockBizStatusEnum.NEED_CHANGE;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        QueryReviewListData(code) {
            const _this = this;
            UrlUtils.QueryRemote(this, `${this.baseUrl}/reviewListData/` + code, (rst) => {
                (async () => {
                    _this.audit.bizCode = code;
                    _this.reviewListData = rst.reviewList;
                    await _this.reviewListData.map((d) => {
                        switch (d.advice) {
                            case 1:
                                d.showAdvice = '同意';
                                break;
                            case 3:
                                d.showAdvice = '反审核';
                                break;
                            case 0:
                                d.showAdvice = '拒绝';
                                break;
                            case 4:
                                d.showAdvice = '驳回';
                                break;
                        }
                    });
                    if (_this.reviewListData.length > 0) {
                        _this.lastReviewData = _this.reviewListData[_this.reviewListData.length - 1];
                    }
                })();
            });
        },
        //公共接口
        show(code, baseUrl, stockStatus) {
            this.AuditDetailDialogTable = true;
            this.baseUrl = baseUrl;
            this.stockStatus = stockStatus;
            this.QueryReviewListData(code);
        },
        reviewAudit() {
            this.nowAdviceBtn = -1;
            this.$refs.review.reviewAndRejectOpen(this.audit.bizCode);
        },
        rejectAudit() {
            this.nowAdviceBtn = -2;
            this.$refs.review.reviewAndRejectOpen(this.audit.bizCode);
        },
        complete() {
            this.AuditDetailDialogTable = false;
            this.goBackAndReload();
        },
    },
};
</script>
